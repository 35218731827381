<template>
  <div class="py-4 py-lg-6 bg-primary">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
          <div>
            <h1 class="text-white mb-1 display-4">{{ combo.name }}</h1>
            <p class="mb-0 text-white lead" v-html="combo.content"></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="py-6">
    <div class="container">
      <div class="row mb-6">
        <div class="col-md-3" v-for="(course, index) in combo.courses.data" :key="'course' + index">
          <div class="card  mb-4 card-hover">
            <router-link :to="{name: 'CourseDetail', params: {code: course.code}}" class="card-img-top">
              <img :src="course.thumbnail.link" alt="" class="rounded-top-md card-img-top" v-if="course.thumbnail !== null">
            </router-link>
            <div class="card-body">
              <h4 class="mb-2">
                <router-link :to="{name: 'CourseDetail', params: {code: course.code}}" class="text-inherit">
                  {{ course.name }}
                </router-link>
              </h4>
              <div class="lh-1">
                                    <span>
                    <i class="mdi mdi-star text-warning me-n1"></i>
                    <i class="mdi mdi-star text-warning me-n1"></i>
                    <i class="mdi mdi-star text-warning me-n1"></i>
                    <i class="mdi mdi-star text-warning me-n1"></i>
                    <i class="mdi mdi-star text-warning"></i>
                  </span>&nbsp;
                <span class="text-warning">4.5</span>
              </div>
            </div>
            <div class="card-footer" v-if="course.teachers && course.teachers.length">
              <div class="row align-items-center g-0">
                <div class="col-auto">
                  <img :src="course.teachers[0].thumbnail ? course.teachers[0].thumbnail.link : '/assets/images/avatar/avatar.jpg'" alt="" class="rounded-circle avatar-xs" />
                </div>
                <div class="col ms-2">
                  <span>{{ course.teachers[0].name }}</span>
                </div>
                <div class="col-auto">
                  <a href="#" class="text-muted bookmark">
                    <i class="fe fe-bookmark  "></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <v-pagination
            v-if="combo.courses.paginatorInfo.lastPage > 1"
            v-model="combo.courses.paginatorInfo.currentPage"
            :pages="combo.courses.paginatorInfo.lastPage"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="loadCombo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "ComboDetail",
  components: {
    VPagination
  },
  data() {
    return {
      combo: {
        name: "",
        content: "",
        courses: {
          data: [],
          paginatorInfo: {
            currentPage: 1,
            lastPage: 0,
            total: 0
          }
        }
      }
    }
  },
  methods: {
    loadCombo() {
      let query = `query {
        combo(code: "${this.$route.params.code}") {
          name
          content
          code
          courses(first: 12, orderBy: [{column: "position", order: ASC}], page: ${this.combo.courses.paginatorInfo.currentPage}) {
            data {
              code
              name
              teachers {
                name
                thumbnail {
                  link
                  media_type
                }
              }
              thumbnail {
                link
              }
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }
      `;
      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.combo) {
              this.combo = data.data.combo;
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.loadCombo();
  }
}
</script>
